import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Button, Stack, TablePagination, useMediaQuery } from "@mui/material";

export default function TableCustomLayout(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Paper sx={{ width: "100%", padding: isMobile ? 2 : 0 }}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Typography
          variant={isMobile ? "subtitle1" : "h6"}
          id="tableTitle"
          component="div"
        >
          {props.title}
        </Typography>
        {props.onDownloadCvs && (
          <Button onClick={props.onDownloadCvs}> Baixar CSV</Button>
        )}
        {props.action}
      </Stack>
      <TableContainer sx={{ maxHeight: props.maxHeight }}>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          padding={isMobile ? "checkbox" : "normal"}
          size="medium"
          {...props.rest}
        >
          {props.children}
        </Table>
      </TableContainer>
      {!props.disablePagination && props.data.length !== 0 && (
        <TablePagination
          rowsPerPageOptions={[props.rowsPerPage ?? 100]}
          component="div"
          count={props?.metadata?.totalElements || props.data.length}
          rowsPerPage={props?.rowsPerPage ?? 100}
          page={props?.page || 0}
          onPageChange={(e, page) => props.onPageChange(page)}
          sx={{ color: "#00A667" }}
        />
      )}
    </Paper>
  );
}

TableCustomLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  data: PropTypes.array.isRequired,
  metadata: PropTypes.object,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  action: PropTypes.node,
  onDownloadCvs: PropTypes.func,
  disablePagination: PropTypes.bool,
};
