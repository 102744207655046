import { Box, Paper, Stack, Typography, useMediaQuery } from "@mui/material";

export default function Card1({
  iconSmall,
  iconLarge,
  title,
  value,
  color = "#EEE",
  backgroundColor = "#0d1015",
  href,
}) {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Paper
      sx={{
        p: 2,
        backgroundColor,
        borderRadius: "8px",
        outline: "1px solid #131e23",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          {iconSmall}

          <Typography
            color={color}
            fontWeight={400}
            fontSize={String(value).length > 10 && isMobile ? 16 : 20}
          >
            {value}
          </Typography>
        </Box>
        <Box>{iconLarge}</Box>
      </Stack>
      <Typography fontWeight={400} fontSize={14}>
        {title}
      </Typography>
    </Paper>
  );
}
